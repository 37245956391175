import jsPDF from 'jspdf';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from 'context/AuthContext';
import { GetCertificate, GetIsPlayerHasCompletedStory } from 'services/certification.service';
import { useNavigate } from 'react-router';
import BB_ROUTES from 'routes/const';

interface ICertificate {
  id: string;
  title: string;
  image: {
    small: { url: string };
    medium: { url: string };
    original: { url: string };
  };
}

const Profile = () => {
  const [certificate, setCertificate] = useState<ICertificate>();
  const [isCompleted, setisCompleted] = useState(false);
  const { user, setBreadCrumbItens } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    setBreadCrumbItens([
      {
        isFirst: true,
        name: 'Inicio/',
        active: false,
        path: BB_ROUTES.HOME(),
      },
      {
        isFirst: false,
        name: `Perfil`,
        active: true,
        path: '/',
      },
    ]);
  }, [setBreadCrumbItens]);

  const getCertificate = useCallback(async () => {
    const response = await GetCertificate();
    if (response) {
      setCertificate(response[0]);
    }
    if (user?._id) {
      const responseStory = await GetIsPlayerHasCompletedStory(user._id);

      if (responseStory.length > 0) {
        setisCompleted(true);
      }
    }
  }, []);

  useEffect(() => {
    getCertificate();
  }, [getCertificate]);

  const handleDownloadCertificate = async () => {
    if (!certificate?.image.original.url) return;

    try {
      const response = await fetch(certificate.image.original.url, { mode: 'cors' });
      const blob = await response.blob();

      const imageUrl = URL.createObjectURL(blob);
      const image = new Image();

      image.src = imageUrl;

      await new Promise(resolve => {
        image.onload = resolve;
      });

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      if (!context) return;

      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0);

      const currentDate = new Date().toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      });

      const headerText = 'A CIPA, certifica que';
      const bodyText1 = `concluiu com sucesso a jornada de abandono predial em caso de incêndio,`;
      const bodyText2 = `no dia ${currentDate}, e está apto a ganhar o selo`;
      const footerText = 'Sabedoria da CIPA.';

      context.textAlign = 'left';
      context.font = '32px Arial';
      context.fillStyle = 'black';
      context.fillText(headerText, 120, 580);

      context.font = 'bold 64px Arial';
      context.fillStyle = '#B21C23';
      context.fillText(user?.name || 'Participante', 120, 660);

      context.font = '32px Arial';
      context.fillStyle = 'black';
      context.fillText(bodyText1, 120, 710);
      context.fillText(bodyText2, 120, 750);

      context.font = 'bold 32px Arial';
      context.fillStyle = 'black';
      context.fillText(footerText, 120, 790);

      const imageData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('landscape', 'px', [canvas.width, canvas.height]);
      pdf.addImage(imageData, 'PNG', 0, 0, canvas.width, canvas.height);

      pdf.save('certificado.pdf');
      URL.revokeObjectURL(imageUrl);
    } catch (error) {
      console.error('Erro ao carregar a imagem:', error);
    }
  };

  return (
    <>
      <h1 className="text-4xl mb-4">Perfil</h1>
      <div className="flex flex-col items-start space-y-5 px-8 py-6 border rounded-lg w-full h-max">
        <h1 className="text-3xl font-semibold text-yellow text-center">Certificado</h1>
        <span className="border-b border-yellow w-full"></span>
        {isCompleted ? (
          <>
            <p className="text-lg text-gray-600 text-center">Visualize o preview do certificado abaixo e faça o download.</p>

            <div className="flex flex-col items-center space-y-3 w-full">
              {certificate?.image.original.url ? (
                <img
                  src={certificate.image.medium.url}
                  alt="Preview do Certificado"
                  className="w-96 md:w-[40em] shadow-lg rounded-lg"
                />
              ) : (
                <p className="text-gray-500">Carregando preview do certificado...</p>
              )}

              <button
                onClick={handleDownloadCertificate}
                className="bg-blue-500 text-white px-4 py-2 rounded border-white border"
              >
                Baixar Certificado
              </button>
            </div>
          </>
        ) : (
          <p>O certificado será emitido quando concluir o curso.</p>
        )}
      </div>
    </>
  );
};

export default Profile;
